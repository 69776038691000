/**
 * AuthError
 */
export const isAuthError = (error) => {
    const authError = error;
    return (authError != null &&
        typeof authError === 'object' &&
        'code' in authError &&
        'severity' in authError &&
        'message' in authError);
};
/** The AuthErrorSeverity of the [[AuthError]] */
export var AuthErrorSeverity;
(function (AuthErrorSeverity) {
    /**
     * This error is potentially recoverable by retrying. For example,
     * if the network is down, the client may attempt to fetch the data again once the network is available.
     */
    AuthErrorSeverity["Error"] = "error";
    /**
     * This error is purely informative. The Auth lifecycle can likely continue,
     * although with potential caveats or risks.
     */
    AuthErrorSeverity["Warn"] = "warn";
    /**
     * Errors of this nature always represent irrecoverable errors.
     * The Auth SDK must be torn down and authentication cannot be attempted again without user input.
     */
    AuthErrorSeverity["Fatal"] = "fatal";
})(AuthErrorSeverity || (AuthErrorSeverity = {}));
/**
 * `AuthErrorCategory` = what was going on when the error occurred
 * @hidden
 */
export var AuthErrorCategory;
(function (AuthErrorCategory) {
    AuthErrorCategory["Unknown"] = "UN";
    AuthErrorCategory["Initialization"] = "IT";
    AuthErrorCategory["Config"] = "CF";
    AuthErrorCategory["Authentication"] = "AN";
    AuthErrorCategory["Temppass"] = "TP";
    AuthErrorCategory["FreeView"] = "FV";
    AuthErrorCategory["Authorization"] = "AZ";
    AuthErrorCategory["Preauthorization"] = "PA";
    AuthErrorCategory["Metadata"] = "MD";
    AuthErrorCategory["Logout"] = "LO";
})(AuthErrorCategory || (AuthErrorCategory = {}));
/**
 * `AuthErrorPlatform` = where the error occurred
 * @hidden
 */
export var AuthErrorPlatform;
(function (AuthErrorPlatform) {
    AuthErrorPlatform["Unknown"] = "00";
    AuthErrorPlatform["Web"] = "01";
    AuthErrorPlatform["iOS"] = "02";
    AuthErrorPlatform["Android"] = "03";
    AuthErrorPlatform["ReactNative"] = "04";
})(AuthErrorPlatform || (AuthErrorPlatform = {}));
/**
 * `AuthErrorDomain` = who to blame for the error
 * @hidden
 */
export var AuthErrorDomain;
(function (AuthErrorDomain) {
    AuthErrorDomain["Unknown"] = "UN";
    AuthErrorDomain["Target"] = "TA";
    AuthErrorDomain["Shared"] = "SH";
    AuthErrorDomain["NativeService"] = "NS";
    AuthErrorDomain["Nexus"] = "NX";
    AuthErrorDomain["Adobe"] = "AE";
    AuthErrorDomain["Runtime"] = "RT";
})(AuthErrorDomain || (AuthErrorDomain = {}));
/**
 * `AuthErrorNumeral` = uniquely identify the cause of the failure
 * @hidden
 * TODO: sync with iOS: AuthBlock/Model/AuthError.swift
 * TODO: sync with Android: com/turner/top/auth/model/AuthError.kt
 */
export var AuthErrorNumeral;
(function (AuthErrorNumeral) {
    AuthErrorNumeral["Unspecified"] = "0000";
    /** Constructing or making the request for MVPD Config failed */
    AuthErrorNumeral["MvpdConfigRequestFailure"] = "0100";
    /** Failed to obtain or process the response from MVPD Config */
    AuthErrorNumeral["MvpdConfigResponseFailure"] = "0101";
    /**
     * If you attempt to select a Provider not enabled in the MVPD Config.
     * It is also possible to be authN'ed passively via SSO with a provider not in the MVPD Config.
     */
    AuthErrorNumeral["MvpdConfigProviderNotFound"] = "0102";
    /** iOS: `buildImageURL()` failure: invalid URL */
    AuthErrorNumeral["UnparseableImageURL"] = "0111";
    /** iOS: `buildImageURL()` failure: network error */
    AuthErrorNumeral["UnreachableImageURL"] = "0112";
    /** iOS: `buildImageURL()` failure: could not build image */
    AuthErrorNumeral["UnqualifiedImageURL"] = "0113";
    /** Uncaught `Error` thrown during `prepare()` */
    AuthErrorNumeral["AdobeInitializationFailure"] = "0200";
    /** If you use an invalid software statement or an invalid requestor ID */
    AuthErrorNumeral["AdobeSetRequestorFailure"] = "0201";
    /** Uncaught `Error` thrown during `fetchAuthContext()` or `login()` */
    AuthErrorNumeral["AdobeAuthenticationFailure"] = "0202";
    /** In the unlikely event that AccessEnabler still says you're authenticated post-`logout()` */
    AuthErrorNumeral["AdobeLogoutFailure"] = "0203";
    /** In the unlikely event that AccessEnabler provides an empty response */
    AuthErrorNumeral["AdobeAuthorizationMissingData"] = "0207";
    /** In the unlikely event that AccessEnabler responds with a different schema */
    AuthErrorNumeral["AdobeAuthorizationInvalidData"] = "0208";
    /** Uncaught `Error` thrown during `fetchTemppassToken()` */
    AuthErrorNumeral["FreeViewUnexpectedFailure"] = "0300";
    /** If authN'ed with a Provider, you are disallowed from accessing a free preview */
    AuthErrorNumeral["FreeViewNotAllowed"] = "0301";
    /** If you attempt to request a free preview, but no TempPass provider is in the MVPD Config */
    AuthErrorNumeral["FreeViewMissingTempPassId"] = "0302";
    /** When your free preview time is up */
    AuthErrorNumeral["FreeViewExpired"] = "0304";
    /** If you attempt to `cancelAuthentication()` when login procedure is not in progress */
    AuthErrorNumeral["CallOutsideOfAuthenticationFlow"] = "0400";
    /**
     * Scenarios with this response:
     * - if you attempt to `logout()` when you're not logged in
     * - if you attempt to `fetchUserMetadata()` when you're not logged in
     * - if you attempt to `authorize()` when you're not logged in
     */
    AuthErrorNumeral["NotAuthenticated"] = "0401";
    /** If you are still authenticated in system settings */
    AuthErrorNumeral["AuthenticatedInSystemSettings"] = "0402";
    /** iOS: exceeded maxAuthenticationPollAttempts */
    AuthErrorNumeral["AuthenticationTimeout"] = "0403";
    /** If you attempt to use SSO in iOS Simulator */
    AuthErrorNumeral["SsoUnavailableInSimulator"] = "0410";
    /** If you attempt to use SSO for a provider who doesn't support it */
    AuthErrorNumeral["SsoUnsupportedByProvider"] = "0411";
    /** If your account with your Provider does not have authorization to watch the requested content */
    AuthErrorNumeral["NotAuthorized"] = "0501";
    AuthErrorNumeral["ClientlessUnknownError"] = "0600";
    AuthErrorNumeral["ClientlessAPIUninitialized"] = "0601";
    AuthErrorNumeral["ClientlessAPIBadRequest"] = "0602";
    AuthErrorNumeral["ClientlessAPIUnexpectedResponseCode"] = "0603";
    AuthErrorNumeral["ClientlessMissingSoftwareStatement"] = "0604";
    AuthErrorNumeral["ClientlessClientRegistrationServiceException"] = "0605";
    AuthErrorNumeral["ClientlessClientRegistrationInvalidResponseBody"] = "0606";
    AuthErrorNumeral["ClientlessClientAccessTokenServiceException"] = "0607";
    AuthErrorNumeral["ClientlessClientAccessTokenInvalidResponseBody"] = "0608";
    AuthErrorNumeral["ClientlessMissingRequestorId"] = "0609";
    AuthErrorNumeral["ClientlessConfigServiceException"] = "0610";
    AuthErrorNumeral["ClientlessConfigInvalidResponseBody"] = "0611";
    AuthErrorNumeral["ClientlessConfigParseError"] = "0612";
    AuthErrorNumeral["ClientlessRegCodeServiceException"] = "0620";
    AuthErrorNumeral["ClientlessRegCodeInvalidResponseBody"] = "0621";
    AuthErrorNumeral["ClientlessRegCodeNotFound"] = "0622";
    AuthErrorNumeral["ClientlessCheckAuthNServiceException"] = "0630";
    AuthErrorNumeral["ClientlessCheckAuthNForbidden"] = "0631";
    AuthErrorNumeral["ClientlessCheckAuthNCanceled"] = "0632";
    AuthErrorNumeral["ClientlessAuthNTokenServiceException"] = "0634";
    AuthErrorNumeral["ClientlessAuthNTokenParseError"] = "0635";
    AuthErrorNumeral["ClientlessAuthNTokenNotFound"] = "0636";
    AuthErrorNumeral["ClientlessAuthNTokenExpired"] = "0637";
    AuthErrorNumeral["ClientlessInvalidClient"] = "0638";
    AuthErrorNumeral["ClientlessAccessDenied"] = "0639";
    AuthErrorNumeral["ClientlessAuthorizeServiceException"] = "0640";
    AuthErrorNumeral["ClientlessAuthorizeParseError"] = "0641";
    AuthErrorNumeral["ClientlessAuthorizeNotAuthorized"] = "0642";
    AuthErrorNumeral["ClientlessAuthZTokenServiceException"] = "0644";
    AuthErrorNumeral["ClientlessAuthZTokenParseError"] = "0645";
    AuthErrorNumeral["ClientlessAuthZTokenNotAuthenticated"] = "0646";
    AuthErrorNumeral["ClientlessAuthZTokenExpired"] = "0647";
    AuthErrorNumeral["ClientlessAuthZTokenNotAuthorized"] = "0648";
    AuthErrorNumeral["ClientlessShortMediaTokenServiceException"] = "0650";
    AuthErrorNumeral["ClientlessShortMediaTokenParseError"] = "0651";
    AuthErrorNumeral["ClientlessShortMediaTokenNotAuthorized"] = "0652";
    AuthErrorNumeral["ClientlessPreauthorizeServiceException"] = "0654";
    AuthErrorNumeral["ClientlessPreauthorizeNoResourcesProvided"] = "0655";
    AuthErrorNumeral["ClientlessPreauthorizeNotAuthorized"] = "0656";
    AuthErrorNumeral["ClientlessPreauthorizeExpired"] = "0657";
    AuthErrorNumeral["ClientlessPreauthorizeNotAuthenticated"] = "0658";
    AuthErrorNumeral["ClientlessUserMetadataServiceException"] = "0680";
    AuthErrorNumeral["ClientlessUserMetadataParseError"] = "0681";
    AuthErrorNumeral["ClientlessUserMetadataNotFound"] = "0682";
    AuthErrorNumeral["ClientlessUserMetadataExpiredToken"] = "0683";
    /** Auth engine failed to init or has not been init'ed yet */
    AuthErrorNumeral["InternalFailure"] = "0900";
    /** If you provide incorrect/invalid parameters to an Auth API */
    AuthErrorNumeral["TypeError"] = "0901";
    /** Incapable of further communication */
    AuthErrorNumeral["BridgeClosed"] = "0902";
})(AuthErrorNumeral || (AuthErrorNumeral = {}));
/**
 * The ErrorCodeBuilder normalizes the steps to building an [[AuthErrorCode]]
 * @hidden
 */
export class AuthErrorCodeBuilder {
    /**
       * Creates an instance of ErrorCodeBuilder.
       *
       * @param {AuthErrorCategory}_category   `[CF]10AE100`
       * @param {AuthErrorPlatform} _platform  `CF[10]AE100`
       * @param {AuthErrorDomain} _domain       `CF10[AE]100`
       * @param {AuthErrorNumeral} _numeral     `CF10AE[100]`
       */
    constructor(category, platform, domain, numeral) {
        this._category = category;
        this._platform = platform;
        this._domain = domain;
        this._numeral = numeral;
    }
    /** Builds the [[AuthErrorCode]] that has been configured */
    build() {
        return `${this._category}${this._platform}${this._domain}${this._numeral}`;
    }
}
var AuthErrorMetadataProperty;
(function (AuthErrorMetadataProperty) {
    AuthErrorMetadataProperty["RecoverySuggestion"] = "recoverySuggestion";
    AuthErrorMetadataProperty["SubErrorCode"] = "subErrorCode";
    AuthErrorMetadataProperty["SubErrorMessage"] = "subErrorMessage";
})(AuthErrorMetadataProperty || (AuthErrorMetadataProperty = {}));
/**
 * A fluent builder of [[AuthError]]
 * @hidden
 */
export class AuthErrorBuilder {
    constructor() {
        this.message = 'Unknown error';
        this.severity = AuthErrorSeverity.Warn;
        this.category = AuthErrorCategory.Unknown;
        this.platform = AuthErrorPlatform.Unknown;
        this.domain = AuthErrorDomain.Unknown;
        this.numeral = AuthErrorNumeral.Unspecified;
        this.platform = AuthErrorPlatform.Web;
    }
    withMessage(message) {
        this.message = message;
        return this;
    }
    withSeverity(severity) {
        this.severity = severity;
        return this;
    }
    withCategory(category) {
        this.category = category;
        return this;
    }
    withPlatform(platform) {
        this.platform = platform;
        return this;
    }
    withDomain(domain) {
        this.domain = domain;
        return this;
    }
    withNumeral(numeral) {
        this.numeral = numeral;
        return this;
    }
    withMetadata(metadata) {
        Object.assign(this.metadata, metadata);
        return this;
    }
    _withMetadataProperty(propertyName, value) {
        if (value != null) {
            this.metadata = this.metadata || {};
            this.metadata[propertyName] = value;
        }
        else if (this.metadata && propertyName in this.metadata) {
            delete this.metadata[propertyName];
        }
        return this;
    }
    withRecoverySuggestion(recoverySuggestion) {
        return this._withMetadataProperty(AuthErrorMetadataProperty.RecoverySuggestion, recoverySuggestion);
    }
    withSubErrorCode(subErrorCode) {
        return this._withMetadataProperty(AuthErrorMetadataProperty.SubErrorCode, subErrorCode);
    }
    withSubErrorMessage(subErrorMessage) {
        return this._withMetadataProperty(AuthErrorMetadataProperty.SubErrorMessage, subErrorMessage);
    }
    getErrorCode() {
        const errorCodeBuilder = new AuthErrorCodeBuilder(this.category, this.platform, this.domain, this.numeral);
        const errorCode = errorCodeBuilder.build();
        return errorCode;
    }
    build() {
        const { severity, message, metadata } = this;
        const code = this.getErrorCode();
        const error = {
            message,
            code,
            severity,
        };
        if (metadata) {
            error.metadata = metadata;
        }
        return error;
    }
}
