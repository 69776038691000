var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * ClientlessAuthEngine
 */
import { ClientlessAPI } from './ClientlessAPI';
import { MetadataKey, } from './ClientlessAuthTypes';
import { AuthErrorBuilder, AuthErrorCategory, AuthErrorDomain, AuthErrorNumeral, AuthErrorSeverity, isAuthError, } from './AuthError';
import { DefaultDeviceInfo } from './DeviceInfo';
import { MVPDConfigAPI } from './MVPDConfigAPI';
import { AuthEngineResponseType, } from './ClientlessAuthEngineResponse';
import { Deferred } from './Deferred';
import { Timer, TimerMode } from './Timer';
import { uuid } from './uuid';
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const STORE_KEY_PREFIX = 'com.turner.top-2';
const DEVICE_ID_STORE_KEY = `${STORE_KEY_PREFIX}.deviceId`;
export class ClientlessAuthEngine {
    constructor(authConfig) {
        this.authConfig = authConfig;
        this._ready = new Deferred();
        this.ready = this._ready.promise;
        this._pollingInterval = 30 * SECOND;
        this._lastClientlessAccessTokenRequestTime = 0;
    }
    prepare() {
        return __awaiter(this, void 0, void 0, function* () {
            const requestorResponse = yield this._setRequestor();
            if (!requestorResponse.status) {
                const error = new AuthErrorBuilder()
                    .withMessage('Failed to set requestor during prepare()')
                    .withCategory(AuthErrorCategory.Initialization)
                    .withDomain(AuthErrorDomain.Nexus)
                    .withNumeral(AuthErrorNumeral.AdobeSetRequestorFailure)
                    .withRecoverySuggestion('Verify the brand/environment/softwareStatement provided in the AuthConfiguration and/or URL Scheme in Info.plist')
                    .build();
                return Promise.reject(error);
            }
            return yield this.checkAuthenticatedStatus();
        });
    }
    buildImageURL(data) {
        return this._configService.buildImageURLString(data);
    }
    checkAuthenticatedStatus() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ready;
            if (!this._clientlessApi)
                return buildAuthEngineError();
            let result = {
                type: AuthEngineResponseType.Did_Set_Authentication_Status,
                isAuthenticated: false,
                info: '',
            };
            try {
                const data = yield this._clientlessApi.requestAuthNToken();
                // will logout if you're logged in with a provider not in the MVPD Config
                try {
                    const providerData = yield this.getSelectedProvider();
                    result.provider = providerData.provider;
                    result.adobeHashId = data.userId;
                    this.cachedUserGuid = data.userId;
                }
                catch (noProvider) {
                    this.cachedUserGuid = undefined;
                    this.cancelAuthentication();
                    result = yield this.logout();
                    result.info = AuthErrorNumeral.MvpdConfigProviderNotFound;
                    return result;
                }
                result.isAuthenticated = true;
            }
            catch (error) {
                if (isAuthError(error)) {
                    const authError = error;
                    result.isAuthenticated = false;
                    result.info = `[${authError.code}] ${authError.message}`;
                    if (authError.code.endsWith(AuthErrorNumeral.ClientlessAccessDenied)) {
                        try {
                            return yield this._retryCheckAuthNWithNewAccessToken();
                        }
                        catch (_a) {
                        }
                    }
                }
            }
            return result;
        });
    }
    getSelectedProvider() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ready;
            if (!this._clientlessApi)
                return buildAuthEngineError();
            const mvpd = this._clientlessApi.getMvpdID();
            let provider = null;
            if (mvpd) {
                provider = this._getMvpdConfigEntry(mvpd) || null;
            }
            if (!provider)
                return Promise.reject(null);
            // FIXME: check if `null` is a valid response, or should we reject?
            const result = {
                type: AuthEngineResponseType.Did_Receive_Selected_Provider,
                provider,
            };
            return result;
        });
    }
    login(presentRegCodeCallback) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ready;
            if (!this._clientlessApi) {
                return buildAuthEngineError();
            }
            try {
                yield this.logout();
            }
            catch (_a) {
            }
            const regcodeResponse = yield this._clientlessApi.requestRegCode();
            // Emit event for regcode
            presentRegCodeCallback({
                registrationCode: regcodeResponse.code,
                registrationURL: this.authConfig.registrationURL,
                helpURL: this.authConfig.helpURL,
            });
            if (this._poller)
                this._poller.cancel();
            const poller = this._pollForAuthNSuccess();
            this._poller = poller;
            const { promise } = poller;
            yield promise;
            return yield this.checkAuthenticatedStatus();
        });
    }
    cancelAuthentication() {
        if (this._poller) {
            this._poller.cancel();
        }
    }
    authorize(channel) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ready;
            if (!this._clientlessApi)
                return buildAuthEngineError();
            yield this._clientlessApi.authorize(channel);
            const shortMediaToken = yield this._clientlessApi.requestShortMediaToken(channel);
            const { serializedToken } = shortMediaToken;
            let decodedToken;
            try {
                decodedToken = window.atob(serializedToken);
            }
            catch (_a) {
            }
            const result = {
                type: AuthEngineResponseType.Did_Set_Token,
                channel,
                token: decodedToken || serializedToken,
            };
            return result;
        });
    }
    // This preauthorize is meant to be used from the client app, not from the second screen regcode app. The REST API
    // call doesn't accept caching options like the AccessEnabler does.
    preauthorize(channels) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ready;
            if (!this._clientlessApi)
                return buildAuthEngineError();
            const response = yield this._clientlessApi.requestPreauthorizedResources(channels);
            const result = {
                type: AuthEngineResponseType.Did_Receive_Preauthorized_Resources,
                decisions: response.resources,
            };
            return result;
        });
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ready;
            if (!this._clientlessApi)
                return buildAuthEngineError();
            try {
                yield this._clientlessApi.logout();
            }
            finally {
                // ensure these are cleared
                this.cachedUserGuid = undefined;
            }
            // successful logout() response
            const result = {
                type: AuthEngineResponseType.Did_Set_Authentication_Status,
                isAuthenticated: false,
                info: '',
            };
            return result;
        });
    }
    fetchMetadata(key, args) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!key)
                return buildFetchMetadataTypeError();
            // Depending upon the key, you have to fetch from different sources.
            switch (key) {
                case MetadataKey.DEVICE_ID:
                    return this._getDeviceIdMetadata();
                case MetadataKey.AUTHENTICATED_TTL:
                    return this._fetchAuthNTTLMetadata();
                case MetadataKey.AUTHORIZED_TTL: {
                    let requestedResource = '';
                    if (typeof args === 'object' && args !== null && !Array.isArray(args)) {
                        const { channel, resource, resourceId, resourceID } = args;
                        requestedResource = channel || resource || resourceId || resourceID || '';
                    }
                    return this._fetchAuthZTTLMetadata(requestedResource);
                }
                default:
                    // ASSUME: only authZ TTL uses `args`
                    return this._fetchUserMetadata(key);
            }
        });
    }
    _setRequestor() {
        return __awaiter(this, void 0, void 0, function* () {
            const { environment, brand, softwareStatement, redirectUri, mvpdConfigURL, serviceAppId, platform, deviceType, deviceInfo, pollingInterval, ecid, } = this.authConfig;
            // Generating UUID for deviceId
            const deviceId = yield getDeviceID();
            const requestorId = brand;
            // Disabling SSO by using a unique appId for requestorId
            const appId = `${requestorId}:top-auth`;
            const MIN_POLLING_INTERVAL = 10 * SECOND;
            const MAX_POLLING_INTERVAL = 2 * MINUTE;
            this._pollingInterval =
                pollingInterval && isFinite(pollingInterval)
                    ? Math.min(Math.max(pollingInterval * SECOND, MIN_POLLING_INTERVAL), MAX_POLLING_INTERVAL)
                    : this._pollingInterval;
            const baseDeviceInfo = {
                model: 'JS',
                osName: 'JS',
                userAgent: 'JS',
                application: appId,
            };
            const defaultDeviceInfo = DefaultDeviceInfo.get();
            this._deviceInfo = Object.assign({}, baseDeviceInfo, defaultDeviceInfo, deviceInfo);
            this._clientlessApi = new ClientlessAPI({
                adobeEnv: environment,
                deviceId,
                deviceInfo: this._deviceInfo,
                deviceType,
                ecid,
                softwareStatement,
                redirectUri,
                requestorId,
            });
            this._configService = new MVPDConfigAPI(mvpdConfigURL, serviceAppId, platform);
            yield Promise.all([
                this._clientlessApi.requestClientRegistration(),
                this._configService.fetchConfig()
            ]);
            yield this._clientlessApi.requestClientAccessToken();
            this._lastClientlessAccessTokenRequestTime = Date.now();
            this._mvpdConfig = this._configService.config;
            // Now let's fetch Adobe's config and merge with ours.
            const adobeConfig = yield this._clientlessApi.requestConfig();
            this._mvpdConfig.mvpd = mergeMVPDLists(this._mvpdConfig.mvpd, adobeConfig.mvpd);
            this._ready.resolve(true);
            const response = {
                type: AuthEngineResponseType.Did_Set_Requestor_Complete,
                status: true,
            };
            return response;
        });
    }
    _retryCheckAuthNWithNewAccessToken() {
        return __awaiter(this, void 0, void 0, function* () {
            if (Date.now() - this._lastClientlessAccessTokenRequestTime < 12 * HOUR) {
                // an access token should last 24 hours!
                throw new Error('Too soon to request new access token');
            }
            yield this._clientlessApi.requestClientAccessToken();
            return this.checkAuthenticatedStatus();
        });
    }
    _getMvpdConfigEntry(mvpdId) {
        if (!this._mvpdConfig || !mvpdId) {
            return;
        }
        return this._mvpdConfig.mvpd.find((mvpd) => mvpd.id == mvpdId);
    }
    _getDeviceIdMetadata() {
        var _a;
        const deviceId = ((_a = this._clientlessApi) === null || _a === void 0 ? void 0 : _a.getDeviceID()) || null;
        const result = {
            type: AuthEngineResponseType.Did_Set_Metadata_Status,
            key: MetadataKey.DEVICE_ID,
            encrypted: false,
            value: deviceId,
        };
        return result;
    }
    _fetchAuthNTTLMetadata() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._clientlessApi)
                return buildAuthEngineError();
            const value = this._clientlessApi.getAuthNTTL();
            const result = {
                type: AuthEngineResponseType.Did_Set_Metadata_Status,
                key: MetadataKey.AUTHENTICATED_TTL,
                encrypted: false,
                value,
            };
            return result;
        });
    }
    _fetchAuthZTTLMetadata(resource = '') {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._clientlessApi)
                return buildAuthEngineError();
            const value = this._clientlessApi.getAuthZTTL(resource);
            const result = {
                type: AuthEngineResponseType.Did_Set_Metadata_Status,
                key: MetadataKey.AUTHORIZED_TTL,
                encrypted: false,
                resource,
                value,
            };
            return result;
        });
    }
    _fetchUserMetadata(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._clientlessApi)
                return buildAuthEngineError();
            const value = yield this._clientlessApi.requestUserMetadataValue(key);
            const result = {
                type: AuthEngineResponseType.Did_Set_Metadata_Status,
                key,
                encrypted: false,
                value,
            };
            return result;
        });
    }
    _pollForAuthNSuccess() {
        if (!this._clientlessApi)
            throw new TypeError('Clientless API is not available');
        const deferred = new Deferred();
        const TIMEOUT_MS = 15 * MINUTE;
        const pollingTimer = Timer(TimerMode.Interval, () => __awaiter(this, void 0, void 0, function* () {
            if (!this._clientlessApi) {
                stop();
                return;
            }
            try {
                const success = yield this._clientlessApi.checkAuthNToken();
                deferred.resolve(success);
                stop();
            }
            catch (error) {
                if (isAuthError(error)) {
                    const authError = error;
                    // Ignore AuthError if it's just telling us you're not authN'ed.
                    // We'll continue polling, unless the error is ServiceException.
                    // Or until 15 minutes have passed.
                    // Or until someone else calls `cancel()`.
                    // (We're returning so it can be used by `cancelAuthentication()`.)
                    // FIXME: authError.code === "AN01AE0631" -- not an AuthErrorNumeral!
                    // Trying to use `.endsWith()`, but that won't exist on old browsers without polyfill.
                    if (authError.code.endsWith(AuthErrorNumeral.ClientlessCheckAuthNServiceException)) {
                        deferred.reject(authError);
                        stop();
                    }
                }
            }
        }), this._pollingInterval);
        const stop = () => {
            pollingTimer.stop();
            timeoutTimer.stop();
        };
        const cancel = (reason) => {
            return () => {
                stop();
                deferred.reject(new AuthErrorBuilder()
                    .withMessage('Canceled authentication')
                    .withCategory(AuthErrorCategory.Authentication)
                    .withDomain(AuthErrorDomain.Shared)
                    .withNumeral(reason)
                    .build());
            };
        };
        const timeoutTimer = Timer(TimerMode.Timeout, cancel(AuthErrorNumeral.AuthenticationTimeout), TIMEOUT_MS);
        pollingTimer.start();
        timeoutTimer.start();
        const result = {
            promise: deferred.promise,
            cancel: cancel(AuthErrorNumeral.ClientlessCheckAuthNCanceled),
        };
        return result;
    }
}
let sessionDeviceId = '';
const getDeviceID = () => {
    if (sessionDeviceId) {
        return sessionDeviceId;
    }
    let deviceId = '';
    deviceId = window.localStorage.getItem(DEVICE_ID_STORE_KEY) || '';
    if (!deviceId) {
        deviceId = uuid();
        window.localStorage.setItem(DEVICE_ID_STORE_KEY, deviceId);
    }
    sessionDeviceId = deviceId;
    return deviceId;
};
const buildAuthEngineError = () => Promise.reject(new AuthErrorBuilder()
    .withMessage('Auth Engine is missing or was disposed')
    .withSeverity(AuthErrorSeverity.Fatal)
    .withCategory(AuthErrorCategory.Initialization)
    .withDomain(AuthErrorDomain.Target)
    .withNumeral(AuthErrorNumeral.ClientlessAPIUninitialized)
    .withRecoverySuggestion('setRequestor() must be called first')
    .build());
const buildFetchMetadataTypeError = () => Promise.reject(new AuthErrorBuilder()
    .withMessage('TypeError: A valid key param is required for "fetchMetadata"')
    .withCategory(AuthErrorCategory.Metadata)
    .withDomain(AuthErrorDomain.Runtime)
    .withNumeral(AuthErrorNumeral.TypeError)
    .build());
/**
 * Merge Adobe's MVPD list with our MVPDConfig allowlist.
 *
 * Considerations:
 *   - our MVPDConfig could include a provider that Adobe no longer supports
 *   - Adobe's config could include a provider that we have not yet onboarded
 *   - Adobe's config could list a provider that our allowlist disables for a platform
 *
 * @param mvpdConfigMVPDs our MVPDConfig list of providers, considered the source
 * @param adobeConfigMVPDs Adobe's MVPD list, used to allow/disallow what's in our MVPDConfig
 *
 * @see `WebAuthEngine`'s `mergeMVPDLists`
 * *Copied from WebAuthEngine.ts, but using Clientless type*
 *
 * @hidden
 */
const mergeMVPDLists = (mvpdConfigMVPDs, adobeConfigMVPDs) => {
    if (!adobeConfigMVPDs || !adobeConfigMVPDs.length)
        return mvpdConfigMVPDs;
    // A look-up table of MVPD IDs in Adobe's config
    const adobeConfigMvpdMap = adobeConfigMVPDs.reduce((lookup, mvpd) => {
        lookup[mvpd.id] = mvpd;
        return lookup;
    }, {});
    // Looping through our MVPD Config, merging with Adobe's provider data, if available
    const newProviders = mvpdConfigMVPDs
        .map((mvpd) => {
        const { id } = mvpd;
        // It's possible a provider in our config does not exist in Adobe's config
        if (id in adobeConfigMvpdMap) {
            const adobeData = adobeConfigMvpdMap[id];
            // Overwrite Adobe's config entry with our config entry -- just need Adobe's extra props
            const result = Object.assign({}, adobeData, mvpd);
            delete result.logoUrl;
            return result;
        }
    })
        // Filter out the `undefined`s from skipping any IDs not in MVPD Config
        .filter(Boolean);
    return newProviders;
};
