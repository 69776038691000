/**
 * ClientlessAuthTypes
 */
export function isClientlessAuthNToken(obj) {
    return (typeof obj === 'object' &&
        obj !== null &&
        typeof obj.expires === 'string' &&
        typeof obj.mvpd === 'string' &&
        typeof obj.requestor === 'string' &&
        typeof obj.userId === 'string');
}
export function isClientlessAuthZToken(obj) {
    return (typeof obj === 'object' &&
        obj !== null &&
        typeof obj.expires === 'string' &&
        typeof obj.mvpd === 'string' &&
        typeof obj.requestor === 'string' &&
        typeof obj.resource === 'string');
}
export function isClientlessUserMetadata(data) {
    return (typeof data === 'object' &&
        data !== null &&
        typeof data.data === 'object' &&
        data.data !== null &&
        typeof data.state === 'string' &&
        typeof data.updated === 'number');
}
/**
 * Adobe AccessEnabler metadata keys, except for `DEVICE_ID`,
 * `AUTHENTICATED_TTL` and `AUTHORIZED_TTL` -- these 3 are special values that
 * are handled differently per platform by the AccessEnabler API.
 */
export var MetadataKey;
(function (MetadataKey) {
    MetadataKey["USER_ID"] = "userID";
    MetadataKey["ZIP"] = "zip";
    MetadataKey["MAX_RATING"] = "maxRating";
    MetadataKey["HOUSEHOLD_ID"] = "householdID";
    MetadataKey["CHANNEL_ID"] = "channelID";
    MetadataKey["HEAD_OF_HOUSEHOLD"] = "is_hoh";
    MetadataKey["TYPE_ID"] = "typeID";
    MetadataKey["PRIMARY_OID"] = "primaryOID";
    MetadataKey["LANGUAGE"] = "language";
    MetadataKey["UPSTREAM_USER_ID"] = "upstreamUserID";
    MetadataKey["HBA_STATUS"] = "hba_status";
    MetadataKey["ON_NET"] = "onNet";
    MetadataKey["IN_HOME"] = "inHome";
    MetadataKey["ALLOW_MIRRORING"] = "allowMirroring";
    // The following are not Adobe keys yet MUST match the keys defined in iOS & Android for Nexus:
    MetadataKey["DEVICE_ID"] = "DEVICE_ID";
    MetadataKey["AUTHENTICATED_TTL"] = "AUTHENTICATED_TTL";
    MetadataKey["AUTHORIZED_TTL"] = "AUTHORIZED_TTL";
})(MetadataKey || (MetadataKey = {}));
export var TrackingDataEventType;
(function (TrackingDataEventType) {
    TrackingDataEventType["MVPD_SELECTION"] = "mvpdSelection";
    TrackingDataEventType["AUTHENTICATION_DETECTION"] = "authenticationDetection";
    TrackingDataEventType["AUTHORIZATION_DETECTION"] = "authorizationDetection";
})(TrackingDataEventType || (TrackingDataEventType = {}));
