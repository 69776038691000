(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("TUB", [], factory);
	else if(typeof exports === 'object')
		exports["TUB"] = factory();
	else
		root["TUB"] = root["TUB"] || {}, root["TUB"]["Auth"] = root["TUB"]["Auth"] || {}, root["TUB"]["Auth"]["Clientless"] = factory();
})(self, function() {
return 