var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/**
 * ClientlessAPI
 */
import parseUrl from 'url-parse';
// @ts-expect-error Could not find a declaration file
import MD5 from 'crypto-js/md5';
import { isClientlessAuthNToken, isClientlessAuthZToken, isClientlessUserMetadata, } from './ClientlessAuthTypes';
import { createAccessTokenRefreshError, createAuthNTokenNetworkError, createAuthNTokenParseError, createAuthorizeNetworkError, createAuthorizeResponseParseError, createAuthZTokenNetworkError, createAuthZTokenParseError, createCheckAuthNTokenNetworkError, createClientAccessTokenBodyError, createClientAccessTokenNetworkError, createClientRegistrationBodyError, createClientRegistrationNetworkError, createConfigBodyError, createConfigNetworkError, createConfigParseError, createLogoutNetworkError, createMissingSoftwareStatementError, createPreauthorizeInvalidInputError, createPreauthorizeNetworkError, createPreauthorizeResponseParseError, createRegCodeNetworkError, createRegCodeParseError, createShortMediaTokenNetworkError, createShortMediaTokenParseError, createUserMetadataNetworkError, createUserMetadataParseError, HTTPError, } from './ClientlessAuthErrors';
const MIN_REGCODE_TTL = 600;
const DEFAULT_REGCODE_TTL = 1800;
const MAX_REGCODE_TTL = 36000;
const ADOBE_STAGING_ENV = 'staging';
const ADOBE_PROD_ORIGIN = 'https://api.auth.adobe.com';
const ADOBE_STAGING_ORIGIN = 'https://api.auth-staging.adobe.com';
const GET = 'GET';
const POST = 'POST';
const DELETE = 'DELETE';
const defaultHeaders = {
    Accept: 'application/json',
};
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const appendQuery = (url, params) => {
    const parsed = parseUrl(url, true);
    parsed.set('query', Object.assign(Object.assign({}, parsed.query), params));
    return parsed.toString();
};
export class ClientlessAPI {
    constructor(opts) {
        this._appVersion = '0.0.1';
        this._hashedUserId = null;
        this._cachedMvpdId = null;
        this._authZTokens = new Map();
        const { adobeEnv, deviceId = '', deviceInfo, deviceType = 'server', ecid, softwareStatement, redirectUri, requestorId, registrationUrl, regcodeTtl = DEFAULT_REGCODE_TTL, hashDeviceId = true, } = opts;
        if (!softwareStatement) {
            throw createMissingSoftwareStatementError();
        }
        if (!requestorId) {
            throw createMissingSoftwareStatementError();
        }
        this._adobeOrigin = adobeEnv === ADOBE_STAGING_ENV ? ADOBE_STAGING_ORIGIN : ADOBE_PROD_ORIGIN;
        this._deviceId = deviceId;
        this._deviceInfo = deviceInfo;
        this._deviceType = deviceType;
        this._ecid = ecid;
        this._softwareStatement = softwareStatement;
        this._redirectUri = redirectUri;
        this._requestorId = requestorId;
        this._registrationUrl = registrationUrl;
        this._hashedDeviceId = this._deviceId && hashDeviceId ? MD5(this._deviceId).toString() : this._deviceId;
        this._regcodeTtl = isFinite(regcodeTtl)
            ? Math.min(Math.max(regcodeTtl, MIN_REGCODE_TTL), MAX_REGCODE_TTL)
            : DEFAULT_REGCODE_TTL;
    }
    requestClientRegistration() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const path = '/o/client/register';
            const reqData = {
                software_statement: this._softwareStatement,
            };
            if (this._redirectUri) {
                reqData['redirect_uri'] = this._redirectUri;
            }
            const base64DeviceInfo = this._getBase64DeviceInfo();
            let res;
            let networkFailure;
            let parseFailure;
            let responseData;
            const headers = Object.assign(Object.assign({}, defaultHeaders), { 'Content-Type': 'application/json' });
            const headersWithDeviceInfo = Object.assign(Object.assign({}, headers), { 'User-Agent': (_b = (_a = this._deviceInfo) === null || _a === void 0 ? void 0 : _a.userAgent) !== null && _b !== void 0 ? _b : 'unknown', 'X-Device-Info': base64DeviceInfo });
            try {
                res = yield this._call({
                    method: POST,
                    path,
                    headers: base64DeviceInfo ? headersWithDeviceInfo : headers,
                    body: JSON.stringify(reqData),
                });
            }
            catch (networkError) {
                if (base64DeviceInfo) {
                    // ASSUME: CORS error from 'X-Device-Info' header
                    try {
                        // retry using query param instead of header
                        res = yield this._call({
                            method: POST,
                            path,
                            headers,
                            body: JSON.stringify(Object.assign(Object.assign({}, reqData), { device_info: base64DeviceInfo })),
                        });
                    }
                    catch (anotherNetworkError) {
                        // whatever, use the first error then
                        networkFailure = networkError;
                    }
                }
                else {
                    networkFailure = networkError;
                }
            }
            if (res) {
                try {
                    responseData = yield res.json();
                }
                catch (bodyError) {
                    parseFailure = bodyError;
                }
            }
            if (networkFailure) {
                return Promise.reject(createClientRegistrationNetworkError(networkFailure, responseData !== null && responseData !== void 0 ? responseData : {}));
            }
            if (parseFailure) {
                return Promise.reject(createClientRegistrationBodyError(parseFailure));
            }
            if (responseData.error) {
                return Promise.reject(createAccessTokenRefreshError(responseData.error));
            }
            this._clientId = responseData.client_id;
            this._clientSecret = responseData.client_secret;
            this._grantType = Array.isArray(responseData.grant_types) && responseData.grant_types.length > 0 ? responseData.grant_types[0] : 'client_credentials';
        });
    }
    requestClientAccessToken() {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            const reqData = {
                client_id: this._clientId,
                client_secret: this._clientSecret,
            };
            if (this._grantType) {
                reqData['grant_type'] = this._grantType;
            }
            const searchParams = new URLSearchParams(reqData);
            try {
                res = yield this._call({
                    method: POST,
                    path: '/o/client/token',
                    headers: Object.assign(Object.assign({}, defaultHeaders), { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }),
                    body: searchParams.toString(),
                });
            }
            catch (networkError) {
                return Promise.reject(createClientAccessTokenNetworkError(networkError));
            }
            try {
                const { access_token, expires_in } = yield res.json();
                this._accessTokenExpiry = Date.now() + expires_in;
                this._accessToken = access_token;
            }
            catch (bodyError) {
                return Promise.reject(createClientAccessTokenBodyError(bodyError));
            }
        });
    }
    /**
       * ## Provide MVPD List
       */
    requestConfig() {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            try {
                res = yield this._call({
                    method: GET,
                    path: `/api/v1/config/${encodeURI(this._requestorId)}`,
                    headers: defaultHeaders,
                });
            }
            catch (networkError) {
                return Promise.reject(createConfigNetworkError(networkError));
            }
            let data;
            try {
                data = yield res.json();
            }
            catch (bodyError) {
                return Promise.reject(createConfigBodyError(res, bodyError));
            }
            try {
                const config = adobeConfigToMvpdList(data);
                if (!config)
                    throw new Error('Missing mvpd data in Adobe config');
                return config;
            }
            catch (parseError) {
                return Promise.reject(createConfigParseError(parseError));
            }
        });
    }
    /**
       * ## Create Registration Code / Login URI
       */
    requestRegCode() {
        return __awaiter(this, void 0, void 0, function* () {
            this._clearCachedData();
            const reqData = this._getDeviceIdParams();
            if (this._registrationUrl)
                reqData.registrationURL = this._registrationUrl;
            if (this._regcodeTtl)
                reqData.ttl = `${this._regcodeTtl}`;
            let res;
            try {
                res = yield this._call({
                    method: POST,
                    path: `/reggie/v1/${encodeURI(this._requestorId)}/regcode`,
                    headers: Object.assign(Object.assign({}, defaultHeaders), { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }),
                    body: new URLSearchParams(reqData).toString(),
                });
            }
            catch (networkError) {
                return Promise.reject(createRegCodeNetworkError(networkError));
            }
            let data;
            try {
                data = yield res.json();
            }
            catch (parseError) {
                return Promise.reject(createRegCodeParseError(parseError));
            }
            return data;
        });
    }
    /**
       * ## Delete Registration Record
       *
       * Deletes the reg code record and releases the reg code for reuse.
       */
    releaseRegCode(regCode = '') {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this._call({
                    method: DELETE,
                    path: `/reggie/v1/${encodeURI(this._requestorId)}/regcode/${encodeURI(regCode)}`,
                    headers: defaultHeaders,
                });
                return true;
            }
            catch (networkError) {
                return Promise.reject(createRegCodeNetworkError(networkError));
            }
        });
    }
    /**
       * ## Check Authentication Token
       *
       * If successful, the Promise resolves.
       *
       * @remarks This call doesn't return any MVPD info.
       * This method is only to be called when polling for login success.
       * Users should call `requestAuthNToken` to update cached MVPD ID.
       */
    checkAuthNToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const searchParams = Object.assign({
                requestor: this._requestorId,
            }, this._getDeviceIdParams());
            try {
                yield this._call({
                    method: GET,
                    path: '/api/v1/checkauthn',
                    headers: defaultHeaders,
                    searchParams,
                });
                return true;
            }
            catch (networkError) {
                this._clearCachedData();
                return Promise.reject(createCheckAuthNTokenNetworkError(networkError));
            }
        });
    }
    /**
       * ## Retrieve Authentication Token
       */
    requestAuthNToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const searchParams = Object.assign({
                requestor: this._requestorId,
            }, this._getDeviceIdParams());
            let res;
            try {
                res = yield this._call({
                    method: GET,
                    path: '/api/v1/tokens/authn',
                    headers: defaultHeaders,
                    searchParams,
                });
            }
            catch (networkError) {
                return Promise.reject(createAuthNTokenNetworkError(networkError));
            }
            let data;
            try {
                data = yield res.json();
                if (!isClientlessAuthNToken(data)) {
                    throw new Error('missing required fields for authn token');
                }
                // convert to a number from adobe string
                data.expires = parseInt(data.expires.toString(), 10);
            }
            catch (parseError) {
                return Promise.reject(createAuthNTokenParseError(parseError));
            }
            this._storeAuthNToken(data);
            return data;
        });
    }
    /**
       * ## Initiate Authorization
       */
    authorize(resource = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const searchParams = Object.assign({
                requestor: this._requestorId,
                resource,
            }, this._getDeviceIdParams());
            let res;
            try {
                res = yield this._call({
                    method: GET,
                    path: '/api/v1/authorize',
                    headers: defaultHeaders,
                    searchParams,
                });
            }
            catch (networkError) {
                return Promise.reject(createAuthorizeNetworkError(networkError));
            }
            let data;
            try {
                data = yield res.json();
                if (!isClientlessAuthZToken(data)) {
                    throw new Error('missing required fields for authn token');
                }
                // expires is defined as a number, but at this point its still a string, this is what converts it.
                data.expires = ensureExpiresValue(data.expires.toString());
            }
            catch (parseError) {
                return Promise.reject(createAuthorizeResponseParseError(parseError));
            }
            this._storeAuthZToken(resource, data);
            return data;
        });
    }
    /**
       * ## Retrieve Authorization Token
       *
       * @example Success: AuthZTokenResponse
       * ```json
       * {
       *   "mvpd": "sampleMvpdId",
       *   "resource": "sampleResourceId",
       *   "requestor": "sampleRequestorId",
       *   "expires": "1348148289000",
       *   "proxyMvpd": "sampleProxyMvpdId"
       * }
       * ```
       *
       * @example Unsuccessful authZ response: AuthZTokenErrorResponse
       * ```json
       * {
       *   "status": 404,
       *   "message": "Not Found",
       *   "details": null
       * }
       * ```
       */
    requestAuthZToken(resource = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const searchParams = Object.assign({
                requestor: this._requestorId,
                resource,
            }, this._getDeviceIdParams());
            let res;
            try {
                res = yield this._call({
                    method: GET,
                    path: '/api/v1/tokens/authz',
                    headers: defaultHeaders,
                    searchParams,
                });
            }
            catch (networkError) {
                return Promise.reject(createAuthZTokenNetworkError(networkError));
            }
            let data;
            try {
                data = yield res.json();
                if (!isClientlessAuthZToken(data)) {
                    throw new Error('missing required fields for authn token');
                }
                // expires is defined as a number, but at this point its still a string, this is what converts it.
                data.expires = ensureExpiresValue(data.expires.toString());
            }
            catch (parseError) {
                return Promise.reject(createAuthZTokenParseError(parseError));
            }
            this._storeAuthZToken(resource, data);
            return data;
        });
    }
    // TODO: test this API call with MRSS
    /**
       * ## Obtain Short Media Token
       */
    requestShortMediaToken(resource = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const searchParams = Object.assign({
                requestor: this._requestorId,
                resource,
            }, this._getDeviceIdParams());
            let res;
            try {
                res = yield this._call({
                    method: GET,
                    path: '/api/v1/tokens/media',
                    headers: defaultHeaders,
                    searchParams,
                });
            }
            catch (networkError) {
                return Promise.reject(createShortMediaTokenNetworkError(networkError));
            }
            let data;
            try {
                data = yield res.json();
            }
            catch (parseError) {
                return Promise.reject(createShortMediaTokenParseError(parseError));
            }
            const { expires } = data;
            data.expires = ensureExpiresValue(expires);
            return data;
        });
    }
    /**
       * ## Retrieve List of Preauthorized Resources
       *
       * @example Sample response:
       * ```json
       * {
       *   "resources": [
       *     {
       *       "id": "TNT",
       *       "authorized": true
       *     },
       *     {
       *       "id": "XYZ",
       *       "authorized": false
       *     }
       *   ]
       * }
       * ```
       */
    requestPreauthorizedResources(resources) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!resources || !Array.isArray(resources) || !resources.length) {
                return Promise.reject(createPreauthorizeInvalidInputError());
            }
            // TODO: sort resources? what other things did we do in web auth?
            const resource = resources.join(',');
            // Keep URL length under 256 bytes: if there's a large list, may use POST
            const method = resource.length < 100 ? GET : POST;
            const reqData = Object.assign({
                requestor: this._requestorId,
                resource,
                deviceId: this._deviceId,
            }, this._getDeviceIdParams());
            let res;
            const opts = {
                method,
                path: '/api/v1/preauthorize/',
                headers: defaultHeaders,
            };
            if (method === POST) {
                opts.body = new URLSearchParams(reqData);
            }
            else {
                opts.searchParams = reqData;
            }
            try {
                res = yield this._call(opts);
            }
            catch (networkError) {
                return Promise.reject(createPreauthorizeNetworkError(networkError));
            }
            let data;
            try {
                data = yield res.json();
            }
            catch (parseError) {
                return Promise.reject(createPreauthorizeResponseParseError(parseError));
            }
            return data;
        });
    }
    /**
       * ## Initiate Logout
       *
       * The logout call currently has the following limitation:
       * it clears the AuthN and AuthZ tokens from storage, but
       * does *not* call the MVPD logout endpoint.
       *
       * NOTE: If you're already logged out, `logout()` is successful!
       */
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            this._clearCachedData();
            try {
                const res = this._call({
                    method: DELETE,
                    path: '/api/v1/logout',
                    headers: defaultHeaders,
                    searchParams: this._getDeviceIdParams(),
                });
                return res;
            }
            catch (networkError) {
                return Promise.reject(createLogoutNetworkError(networkError));
            }
        });
    }
    /**
       * ## User Metadata
       */
    requestUserMetadata() {
        return __awaiter(this, void 0, void 0, function* () {
            const searchParams = Object.assign({
                requestor: this._requestorId,
            }, this._getDeviceIdParams());
            let res;
            try {
                res = yield this._call({
                    method: GET,
                    path: '/api/v1/tokens/usermetadata',
                    headers: defaultHeaders,
                    searchParams,
                });
            }
            catch (networkError) {
                return Promise.reject(createUserMetadataNetworkError(networkError));
            }
            let data;
            try {
                data = yield res.json();
                if (!isClientlessUserMetadata(data)) {
                    throw new Error('missing required fields for user metadata');
                }
            }
            catch (parseError) {
                return Promise.reject(createUserMetadataParseError(parseError));
            }
            return data;
        });
    }
    setAccessToken(accessToken) {
        this._accessToken = accessToken;
    }
    getAccessToken() {
        return this._accessToken;
    }
    getDeviceID() {
        return this._hashedDeviceId || null;
    }
    getUserID() {
        return this._hashedUserId || null;
    }
    getMvpdID() {
        return this._cachedMvpdId || null;
    }
    getAuthNTTL() {
        const authNToken = this._getCachedAuthNToken();
        if (authNToken) {
            const { expires = null } = authNToken;
            return expires;
        }
        return null;
    }
    getAuthZTTL(resource) {
        if (!resource)
            return null;
        const authZToken = this._getCachedAuthZToken(resource);
        if (authZToken) {
            const { expires = null } = authZToken;
            return expires;
        }
        return null;
    }
    // TODO: Determine when `encrypted` is true, and how that affects data.
    // TODO: Determine what to do with non-simple data, e.g. `channelID` and
    // `zip` are arrays of strings, and `maxRating` is a dictionary!
    // TODO: Determine what the possible values are for undocumented metadata,
    // i.e. `inHome`, `language`, `onNet`.
    // TODO: Ensure documented booleans are not strings, i.e. `allowMirroring`
    // and `hba_status`.
    /**
       * ### Request a single metadata value
       */
    requestUserMetadataValue(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!key)
                return null;
            const userMetadata = yield this._updateCachedUserMetadata();
            const { data } = userMetadata;
            const value = typeof data === 'object' && data !== null && !Array.isArray(data) ? data[key] : null;
            return value;
        });
    }
    _call(_a) {
        var { path, method = GET, headers = {}, searchParams = {}, body } = _a, rest = __rest(_a, ["path", "method", "headers", "searchParams", "body"]);
        return __awaiter(this, void 0, void 0, function* () {
            if (!path)
                throw new Error('[ClientlessAPI:call] A "path" is required.');
            let url = `${this._adobeOrigin}${path}`;
            if (this._softwareStatement && this._accessToken) {
                // 30m before expiry get a new token
                if (Date.now() > this._accessTokenExpiry - 1800000) {
                    this._accessToken = void 0;
                    this._accessTokenExpiry = void 0;
                    yield this.requestClientAccessToken();
                }
                headers['Authorization'] = 'Bearer ' + this._accessToken;
            }
            const newOpts = Object.assign({ method,
                headers,
                body }, rest);
            // If an ExperienceCloudId (ECID) is provided, send it on *all* API calls.
            if (this._ecid) {
                searchParams.ap_vi = this._ecid;
            }
            if (Object.keys(searchParams).length > 0) {
                url = appendQuery(url, searchParams);
            }
            const res = yield fetch(url, newOpts);
            if (!res.ok)
                throw new HTTPError(res);
            return res;
        });
    }
    _getDeviceIdParams() {
        const deviceIdParams = {
            deviceId: this._hashedDeviceId,
            deviceType: this._deviceType,
        };
        if (this._appVersion)
            deviceIdParams.appVersion = this._appVersion;
        return deviceIdParams;
    }
    _clearCachedData() {
        this._hashedUserId = null;
        this._cachedMvpdId = null;
        this._userMetadata = undefined;
        this._authNToken = undefined;
        this._authZTokens.clear();
    }
    _getBase64DeviceInfo() {
        try {
            return window.btoa(JSON.stringify(this._deviceInfo));
        }
        catch (_a) {
            return '';
        }
    }
    _storeAuthNToken(authNToken) {
        this._authNToken = authNToken;
        const { userId, mvpd } = authNToken;
        this._hashedUserId = userId || null;
        this._cachedMvpdId = mvpd || null;
    }
    _storeAuthZToken(resource, authZToken) {
        this._authZTokens.set(resource, authZToken);
    }
    _getCachedAuthNToken() {
        let authNToken = this._authNToken;
        if (authNToken) {
            const { expires } = authNToken;
            if (!expires || Date.now() > expires) {
                authNToken = this._authNToken = undefined;
            }
        }
        return authNToken;
    }
    _getCachedAuthZToken(resource) {
        let authZToken = this._authZTokens.get(resource);
        if (authZToken) {
            const { expires } = authZToken;
            const number = Number(expires);
            if (!number || Date.now() > number) {
                this._authZTokens.delete(resource);
                authZToken = undefined;
            }
        }
        return authZToken;
    }
    _updateCachedUserMetadata() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._userMetadata ||
                !this._userMetadataUpdated ||
                Math.abs(Date.now() - this._userMetadataUpdated) > 12 * HOUR) {
                const userMetadata = yield this.requestUserMetadata();
                this._userMetadata = userMetadata;
                const { updated = Date.now() } = userMetadata;
                this._userMetadataUpdated = updated;
            }
            return this._userMetadata;
        });
    }
}
const ensureExpiresValue = (expires) => {
    const number = Number(expires);
    if (!isNaN(number) && number && isFinite(number))
        return number;
    return Date.now() + 5 * MINUTE;
};
const normalizeAdobeConfigItem = (item) => {
    if (Array.isArray(item)) {
        return item.map(normalizeAdobeConfigItem);
    }
    else if (typeof item === 'object' && item !== null) {
        return 'value' in item
            ? item.value
            : Object.keys(item).reduce((memo, key) => {
                memo[key] = normalizeAdobeConfigItem(item[key]);
                return memo;
            }, {});
    }
    else {
        return item;
    }
};
const adobeConfigToMvpdList = (adobeConfig) => {
    try {
        const normalizedConfig = normalizeAdobeConfigItem(adobeConfig);
        return normalizedConfig.requestor.mvpds.mvpd;
    }
    catch (_a) {
    }
};
