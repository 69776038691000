/**
 * Clientless Dynamic Client Registration requires
 * information about the device/platform.
 */
export class DefaultDeviceInfo {
    static get() {
        const defaultDeviceInfo = {};
        Object.assign(defaultDeviceInfo, DefaultDeviceInfo.getNavigatorInfo());
        Object.assign(defaultDeviceInfo, DefaultDeviceInfo.getScreenInfo());
        return defaultDeviceInfo;
    }
    static getNavigatorInfo() {
        const navigatorInfo = {};
        if (!navigator) {
            return navigatorInfo;
        }
        if (navigator.userAgent) {
            navigatorInfo.userAgent = navigator.userAgent.toString();
        }
        // Connection exists on Chrome 61+, Edge 79+, Firefox 31-32, Opera 48, and never on Safari.
        // If it is available, we can use it, if not, we won't.
        const connection = navigator.connection;
        if (connection) {
            const { type, effectiveType } = connection;
            switch (type) {
                case 'wifi':
                    navigatorInfo.connectionType = 'WiFi';
                    break;
                case 'ethernet':
                    navigatorInfo.connectionType = 'LAN';
                    break;
                default:
                    if (effectiveType) {
                        navigatorInfo.connectionType = effectiveType;
                    }
                    break;
            }
        }
        return navigatorInfo;
    }
    static getScreenInfo() {
        const screenInfo = {};
        if (typeof screen !== 'undefined') {
            const { width, height } = screen;
            if (isFinite(width) && isFinite(height) && width * height > 200) {
                const pixelRatio = (isFinite(devicePixelRatio) && devicePixelRatio) || 1;
                const pixelWidth = width * pixelRatio;
                const pixelHeight = height * pixelRatio;
                const ppi = 96 * pixelRatio;
                const horizontal = pixelWidth / ppi;
                const vertical = pixelHeight / ppi;
                const diagonal = Math.sqrt(Math.pow(horizontal, 2) + Math.pow(vertical, 2));
                screenInfo.displayWidth = pixelWidth.toFixed(2);
                screenInfo.displayHeight = pixelHeight.toFixed(2);
                screenInfo.displayPpi = ppi.toFixed(2);
                screenInfo.diagonalScreenSize = diagonal.toFixed(2);
            }
        }
        return screenInfo;
    }
    constructor() {
        return DefaultDeviceInfo.get();
    }
}
