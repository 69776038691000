export var TimerMode;
(function (TimerMode) {
    TimerMode["Timeout"] = "timeout";
    TimerMode["Interval"] = "interval";
})(TimerMode || (TimerMode = {}));
export const Timer = (mode, handler, time) => {
    let timerId = -1;
    let start;
    let stop;
    switch (mode) {
        case TimerMode.Interval:
            start = () => {
                timerId = setInterval(handler, time);
            };
            stop = () => {
                clearInterval(timerId);
                timerId = -1;
            };
            break;
        case TimerMode.Timeout:
            start = () => {
                timerId = setTimeout(handler, time);
            };
            stop = () => {
                clearTimeout(timerId);
                timerId = -1;
            };
            break;
    }
    return { start, stop };
};
