/**
 * AuthConfiguration
 */
import { AuthErrorBuilder, AuthErrorCategory, AuthErrorDomain, AuthErrorNumeral, AuthErrorPlatform, AuthErrorSeverity, } from './AuthError';
import { DefaultDeviceInfo } from './DeviceInfo';
/**
 * The AuthConfigBuilder
 *
 * Helper class to create various Auth configs.
 *
 * @public
 */
export class AuthConfigBuilder {
    /**
       * (Required) `platform` is used to pluck data from MVPDConfig
       */
    withPlatform(platform) {
        this._platform = platform.toLowerCase();
        return this;
    }
    /**
       * (Required) `mvpdConfigURL` is the allowlist of MVPDs (TV service providers)
       */
    withMVPDConfigURL(url) {
        this._mvpdConfigURL = url;
        return this;
    }
    /**
       * (Required) The value is provided to services for MVPDConfig, Tokens, SBP, etc.
       */
    withServiceAppId(serviceAppId) {
        this._serviceAppId = serviceAppId;
        return this;
    }
    /**
       * (Required for 'clientful' & 'clientless') AccessEnabler / REST API softwareStatement
       */
    withSoftwareStatement(softwareStatement) {
        this._softwareStatement = softwareStatement;
        return this;
    }
    /**
       * (Optional: deviceType defaults to `platform` value) Clientless: Override platform value
       * Specify `"xboxOneUWP"` if you are building for Universal Windows Platform.
       */
    withDeviceType(deviceType) {
        this._deviceType = deviceType;
        return this;
    }
    /**
       * (Required)
       */
    withBrand(brand) {
        this._brand = brand;
        return this;
    }
    /**
       * (Recommended) The URL to which the Picker will send users who request help
       */
    withHelpURL(url) {
        this._helpURL = url;
        return this;
    }
    /**
       * (Optional) Adobe Cloud ID
       */
    withExperienceCloudId(ecid) {
        this._ecid = ecid;
        return this;
    }
    /**
       * (Optional) Defaults to 'production'
       */
    withEnvironment(environment) {
        this._environment = environment;
        return this;
    }
    /**
       * (Recommended for 'clientless' [[AuthType]]) The URL of the second screen activation page to which users will be directed in order to login
       */
    withRegistrationURL(url) {
        this._registrationURL = url;
        return this;
    }
    /**
       * (Optional for 'clientless') Defaults to checking authN status every 30 seconds
       */
    withPollingInterval(interval) {
        this._pollingInterval = interval;
        return this;
    }
    /**
       * Clientless: Specify device information (e.g. "model", "osName", "application", "userAgent") when registering client with REST API
       */
    withDeviceInfo(deviceInfo) {
        this._deviceInfo = deviceInfo;
        return this;
    }
    /**
       * (Optional) Clientless: Specify redirectUri for DCR
       * The URI that the application uses for completing the authentication flow.
       * (This sounds like something AccessEnabler does, but our Clientless impl wouldn't.
       * Post-login with AccessEnabler, native iOS/Android load an invalid URL in the webview.
       * But we don't have a webview in our Clientless impl. Our login flow occurs via an
       * activation web page on a 2nd screen.)
       */
    withRedirectUri(redirectUri) {
        this._redirectUri = redirectUri;
        return this;
    }
    /**
       * @public
       */
    build() {
        if (!this._softwareStatement) {
            throw configError('softwareStatement or publicKey/secretKey');
        }
        if (!this._platform)
            throw configError('platform');
        if (!this._mvpdConfigURL)
            throw configError('mvpdConfigURL');
        if (!this._brand)
            throw configError('brand');
        if (!this._serviceAppId)
            throw configError('serviceAppId');
        const mergedDeviceInfo = Object.assign(Object.assign({}, DefaultDeviceInfo.get()), this._deviceInfo);
        return {
            platform: this._platform,
            mvpdConfigURL: this._mvpdConfigURL,
            serviceAppId: this._serviceAppId,
            softwareStatement: this._softwareStatement || '',
            brand: this._brand,
            helpURL: this._helpURL,
            ecid: this._ecid,
            environment: this._environment || EnvironmentName.PRODUCTION,
            registrationURL: this._registrationURL,
            deviceType: this._deviceType || this._platform,
            pollingInterval: this._pollingInterval,
            deviceInfo: mergedDeviceInfo,
            redirectUri: this._redirectUri,
        };
    }
}
const configError = (missingRequiredProp) => {
    return new AuthErrorBuilder()
        .withMessage(`missing required property: ${missingRequiredProp}`)
        .withSeverity(AuthErrorSeverity.Fatal)
        .withCategory(AuthErrorCategory.Config)
        .withPlatform(AuthErrorPlatform.Unknown)
        .withDomain(AuthErrorDomain.Shared)
        .withNumeral(AuthErrorNumeral.Unspecified)
        .build();
};
export var EnvironmentName;
(function (EnvironmentName) {
    EnvironmentName["PRODUCTION"] = "production";
    EnvironmentName["STAGING"] = "staging";
})(EnvironmentName || (EnvironmentName = {}));
