var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { buildMvpdConfigInvalidDataError, buildMvpdConfigJsonParseError, buildMvpdConfigRequestError, } from './ClientlessAuthErrors';
const templateTokenRegEx = /\$\{([^}]*)\}/g;
const tokenReplacer = (tokenRegEx) => (template, data) => template.replace(tokenRegEx, (str, key) => {
    let value = null;
    if (key in data)
        value = String(data[key]);
    return value != null ? value : '';
});
/**
 * Interpolate = replace template tokens
 *
 * @example
 * ```
 * interpolate(
 *   "String with ${token} substrings, missing ${tokens} are removed",
 *   {
 *     token: "replacement"
 *   }
 * )
 * ```
 */
export const interpolate = tokenReplacer(templateTokenRegEx);
export class MVPDConfigAPI {
    constructor(mvpdConfigUrl, appId, platform) {
        this.mvpdConfigUrl = mvpdConfigUrl;
        this.appId = appId;
        this.platform = platform;
    }
    /**
       * Fetches the WarnerMedia MVPD Config, functions as both a white-list and provides additional data.
       */
    fetchConfig() {
        return __awaiter(this, void 0, void 0, function* () {
            // Only fetch the config once
            if (this.config)
                return Promise.resolve(this.config);
            let data;
            try {
                let response;
                // Make request
                try {
                    const appIdHeader = 'app-id';
                    const headers = new Headers();
                    headers.set(appIdHeader, this.appId);
                    const request = new Request(this.mvpdConfigUrl.toString(), { headers });
                    response = yield fetch(request);
                }
                catch (requestError) {
                    throw buildMvpdConfigRequestError(requestError);
                }
                // Process response
                try {
                    data = yield response.json();
                }
                catch (responseError) {
                    throw buildMvpdConfigJsonParseError();
                }
            }
            catch (error) {
                return Promise.reject(error);
            }
            this.config = data;
            return this.config;
        });
    }
    /**
       * Construct an image URL string from [[ImageTemplateBuilderData]]
       *
       * @param {ImageTemplateBuilderData} imageData The required parameters to construct an image URL
       * @returns {Promise<ImageURLDataResponse>} The `imageURL` is inside, if successful
       */
    buildImageURLString(imageData) {
        if (!this.config) {
            throw buildMvpdConfigInvalidDataError();
        }
        const template = imageData.context == 'primary' ? this.config.primaryImgTemplate : this.config.cobrandingImgTemplate;
        return interpolate(template, imageData);
    }
}
