/**
 * ClientlessAuthErrors
 */
import { AuthErrorBuilder, AuthErrorCategory, AuthErrorDomain, AuthErrorNumeral, AuthErrorSeverity, } from './AuthError';
/** @hidden */
export class HTTPError extends Error {
    constructor(response) {
        super(`${response.status}`);
        this.name = 'HTTPError';
        this.response = response;
    }
    static is(object) {
        const httpError = object;
        return (httpError != null &&
            typeof httpError === 'object' &&
            'name' in httpError &&
            httpError.name === 'HTTPError' &&
            'response' in httpError &&
            httpError.response != null);
    }
}
/** @hidden */
export class ClientlessAuthErrorBuilder extends AuthErrorBuilder {
    constructor() {
        super();
        this.withDomain(AuthErrorDomain.Adobe);
    }
}
const { ClientlessUnknownError, ClientlessAPIBadRequest, ClientlessAPIUnexpectedResponseCode, ClientlessMissingSoftwareStatement, ClientlessMissingRequestorId, ClientlessClientRegistrationServiceException, ClientlessClientRegistrationInvalidResponseBody, ClientlessClientAccessTokenServiceException, ClientlessClientAccessTokenInvalidResponseBody, ClientlessConfigServiceException, ClientlessConfigInvalidResponseBody, ClientlessConfigParseError, ClientlessRegCodeServiceException, ClientlessRegCodeInvalidResponseBody, ClientlessRegCodeNotFound, ClientlessCheckAuthNServiceException, ClientlessCheckAuthNForbidden, ClientlessAuthNTokenServiceException, ClientlessAuthNTokenParseError, ClientlessAuthNTokenNotFound, ClientlessAuthNTokenExpired, ClientlessInvalidClient, ClientlessAccessDenied, ClientlessPreauthorizeServiceException, ClientlessPreauthorizeNoResourcesProvided, ClientlessPreauthorizeNotAuthorized, ClientlessPreauthorizeExpired, ClientlessPreauthorizeNotAuthenticated, ClientlessAuthorizeServiceException, ClientlessAuthorizeNotAuthorized, ClientlessAuthZTokenServiceException, ClientlessAuthZTokenParseError, ClientlessAuthZTokenNotAuthenticated, ClientlessAuthZTokenExpired, ClientlessAuthZTokenNotAuthorized, ClientlessShortMediaTokenServiceException, ClientlessShortMediaTokenParseError, ClientlessShortMediaTokenNotAuthorized, ClientlessUserMetadataServiceException, ClientlessUserMetadataParseError, ClientlessUserMetadataNotFound, ClientlessUserMetadataExpiredToken, } = AuthErrorNumeral;
const { Initialization, Config, Authentication, Authorization, Metadata } = AuthErrorCategory;
const numeralToStringMap = {
    [ClientlessUnknownError]: 'Unknown Error',
    [ClientlessAPIBadRequest]: 'Bad Request',
    [ClientlessAPIUnexpectedResponseCode]: 'Unexpected Response Code',
    [ClientlessMissingSoftwareStatement]: 'Missing Software Statement',
    [ClientlessMissingRequestorId]: 'Missing Requestor ID',
    [ClientlessClientRegistrationServiceException]: 'Client Registration Service Exception',
    [ClientlessClientRegistrationInvalidResponseBody]: 'Invalid Client Registration Response Body',
    [ClientlessClientAccessTokenServiceException]: 'Client Access Token Service Exception',
    [ClientlessClientAccessTokenInvalidResponseBody]: 'Invalid Client Access Token Response Body',
    [ClientlessConfigServiceException]: 'Config Service Exception',
    [ClientlessConfigInvalidResponseBody]: 'Invalid Config Response Body',
    [ClientlessConfigParseError]: 'Config Parse Error',
    [ClientlessRegCodeServiceException]: 'Reg Code Service Exception',
    [ClientlessRegCodeInvalidResponseBody]: 'Invalid Reg Code Response Body',
    [ClientlessRegCodeNotFound]: 'Reg Code Not Found',
    [ClientlessCheckAuthNServiceException]: 'Check AuthN Status Service Exception',
    [ClientlessCheckAuthNForbidden]: 'Check AuthN Status Forbidden',
    [ClientlessAuthNTokenServiceException]: 'Authentication Token Service Exception',
    [ClientlessAuthNTokenParseError]: 'Authentication Token Parse Error',
    [ClientlessAuthNTokenNotFound]: 'Authentication Token Not Found',
    [ClientlessAuthNTokenExpired]: 'Authentication Token Expired',
    [ClientlessPreauthorizeServiceException]: 'Preauthorize Service Exception',
    [ClientlessPreauthorizeNoResourcesProvided]: 'No Resources Provided',
    [ClientlessPreauthorizeNotAuthorized]: 'Not Authorized',
    [ClientlessPreauthorizeExpired]: 'Expired',
    [ClientlessPreauthorizeNotAuthenticated]: 'Not Authenticated',
    [ClientlessAuthorizeServiceException]: 'Authorize Service Exception',
    [ClientlessAuthorizeNotAuthorized]: 'Not Authorized',
    [ClientlessAuthZTokenServiceException]: 'Authorization Token Service Exception',
    [ClientlessAuthZTokenParseError]: 'Authorization Token Parse Error',
    [ClientlessAuthZTokenNotAuthenticated]: 'Not Authenticated',
    [ClientlessAuthZTokenExpired]: 'Authorization Token Expired',
    [ClientlessAuthZTokenNotAuthorized]: 'Not Authorized',
    [ClientlessShortMediaTokenServiceException]: 'Short Media Token Service Exception',
    [ClientlessShortMediaTokenParseError]: 'Short Media Token Parse Error',
    [ClientlessShortMediaTokenNotAuthorized]: 'Not Authorized',
    [ClientlessUserMetadataServiceException]: 'User Metadata Service Exception',
    [ClientlessUserMetadataParseError]: 'User Metadata Parse Error',
    [ClientlessUserMetadataNotFound]: 'User Metadata Not Found',
    [ClientlessUserMetadataExpiredToken]: 'Expired Token',
};
const describeNumeral = (numeral) => { var _a; return (_a = numeralToStringMap[numeral]) !== null && _a !== void 0 ? _a : 'Unknown error'; };
export const buildMvpdConfigRequestError = (requestError) => {
    const error = new AuthErrorBuilder()
        .withSeverity(AuthErrorSeverity.Fatal)
        .withMessage('MVPD Config request failure')
        .withCategory(AuthErrorCategory.Config)
        .withDomain(AuthErrorDomain.Shared)
        .withNumeral(AuthErrorNumeral.MvpdConfigRequestFailure);
    if (requestError instanceof Response) {
        error.withSubErrorCode(`${requestError.status} ${requestError.statusText}`);
        error.withSubErrorMessage(requestError.url);
    }
    return error.build();
};
export const buildMvpdConfigJsonParseError = () => {
    return new AuthErrorBuilder()
        .withSeverity(AuthErrorSeverity.Fatal)
        .withMessage('MVPD Config failed to parse as JSON')
        .withCategory(AuthErrorCategory.Config)
        .withDomain(AuthErrorDomain.Shared)
        .withNumeral(AuthErrorNumeral.MvpdConfigResponseFailure)
        .build();
};
export const buildMvpdConfigInvalidDataError = () => {
    return new AuthErrorBuilder()
        .withSeverity(AuthErrorSeverity.Fatal)
        .withMessage('MVPD Config has no data or an invalid schema')
        .withCategory(AuthErrorCategory.Config)
        .withDomain(AuthErrorDomain.Shared)
        .withNumeral(AuthErrorNumeral.MvpdConfigResponseFailure)
        .build();
};
/** @hidden */
export const createMissingSoftwareStatementError = () => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage('Missing software statement')
        .withCategory(Initialization)
        .withNumeral(ClientlessMissingSoftwareStatement)
        .build();
    return authError;
};
/** @hidden */
export const createAccessTokenRefreshError = (error) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage('Failed to refresh access token')
        .withCategory(Initialization)
        .withNumeral(ClientlessClientAccessTokenServiceException)
        .withSubErrorMessage(error)
        .build();
    return authError;
};
/** @hidden */
export const createClientRegistrationNetworkError = (networkError, data) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
        };
        numeral = codeToNumeralMap[status] || ClientlessClientRegistrationServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Initialization)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createClientRegistrationBodyError = (bodyError) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(bodyError) || 'Client registration response data JSON parse failure')
        .withCategory(Initialization)
        .withNumeral(ClientlessClientRegistrationInvalidResponseBody)
        .build();
    return authError;
};
/** @hidden */
export const createClientAccessTokenNetworkError = (networkError, data) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
            404: ClientlessRegCodeNotFound,
        };
        numeral = codeToNumeralMap[status] || ClientlessClientAccessTokenServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Initialization)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createClientAccessTokenBodyError = (bodyError) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(bodyError) || 'Client access token response data JSON parse failure')
        .withCategory(Initialization)
        .withNumeral(ClientlessClientAccessTokenInvalidResponseBody)
        .build();
    return authError;
};
/** @hidden */
export const createConfigNetworkError = (networkError) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        numeral = ClientlessConfigServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Config)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createConfigBodyError = (res, bodyError) => {
    const { status, url } = res;
    const httpStatus = `${status}`;
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(bodyError) || 'Failed to obtain text body')
        .withCategory(Authentication)
        .withNumeral(ClientlessConfigInvalidResponseBody)
        .withSubErrorCode(httpStatus)
        .withSubErrorMessage(url)
        .build();
    return authError;
};
/** @hidden */
export const createConfigParseError = (parseError) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(parseError) || 'Config parse failure')
        .withCategory(Authentication)
        .withNumeral(ClientlessConfigParseError)
        .build();
    return authError;
};
/** @hidden */
export const createRegCodeNetworkError = (networkError) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
            401: ClientlessAccessDenied,
            404: ClientlessRegCodeNotFound,
        };
        numeral = codeToNumeralMap[status] || ClientlessRegCodeServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Authentication)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createRegCodeParseError = (parseError) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(parseError) || 'Regcode response data JSON parse failure')
        .withCategory(Authentication)
        .withNumeral(ClientlessRegCodeInvalidResponseBody)
        .build();
    return authError;
};
/** @hidden */
export const createCheckAuthNTokenNetworkError = (networkError) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
            403: ClientlessCheckAuthNForbidden,
        };
        numeral = codeToNumeralMap[status] || ClientlessCheckAuthNServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Authentication)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createAuthNTokenNetworkError = (networkError) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
            401: ClientlessAccessDenied,
            403: ClientlessInvalidClient,
            404: ClientlessAuthNTokenNotFound,
            410: ClientlessAuthNTokenExpired,
        };
        numeral = codeToNumeralMap[status] || ClientlessAuthNTokenServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Authentication)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createAuthNTokenParseError = (parseError) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(parseError) || 'AuthN token response data JSON parse failure')
        .withCategory(Authentication)
        .withNumeral(ClientlessAuthNTokenParseError)
        .build();
    return authError;
};
/** @hidden */
export const createPreauthorizeInvalidInputError = () => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage('No resources provided')
        .withCategory(Authorization)
        .withNumeral(ClientlessPreauthorizeNoResourcesProvided)
        .build();
    return authError;
};
/** @hidden */
export const createPreauthorizeNetworkError = (networkError) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
            401: ClientlessPreauthorizeNotAuthenticated,
            404: ClientlessPreauthorizeNotAuthorized,
            410: ClientlessPreauthorizeExpired,
            412: ClientlessPreauthorizeNotAuthenticated,
        };
        numeral = codeToNumeralMap[status] || ClientlessPreauthorizeServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Authorization)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createPreauthorizeResponseParseError = (parseError) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(parseError) || 'Preauthorize response data JSON parse failure')
        .withCategory(Authorization)
        .withNumeral(ClientlessAuthZTokenParseError)
        .build();
    return authError;
};
/** @hidden */
export const createAuthorizeNetworkError = (networkError) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
            403: ClientlessAuthorizeNotAuthorized,
        };
        numeral = codeToNumeralMap[status] || ClientlessAuthorizeServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Authorization)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createAuthorizeResponseParseError = (parseError) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(parseError) || 'Authorize response data JSON parse failure')
        .withCategory(Authorization)
        .withNumeral(ClientlessAuthZTokenParseError)
        .build();
    return authError;
};
/** @hidden */
export const createAuthZTokenNetworkError = (networkError) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
            404: ClientlessAuthZTokenNotAuthorized,
            410: ClientlessAuthZTokenExpired,
            412: ClientlessAuthZTokenNotAuthenticated,
        };
        numeral = codeToNumeralMap[status] || ClientlessAuthZTokenServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Authorization)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createAuthZTokenParseError = (parseError) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(parseError) || 'AuthZ token response data JSON parse failure')
        .withCategory(Authorization)
        .withNumeral(ClientlessAuthZTokenParseError)
        .build();
    return authError;
};
/** @hidden */
export const createShortMediaTokenNetworkError = (networkError) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
            403: ClientlessShortMediaTokenNotAuthorized,
        };
        numeral = codeToNumeralMap[status] || ClientlessShortMediaTokenServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Authorization)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createShortMediaTokenParseError = (parseError) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(parseError) || 'AuthZ token response data JSON parse failure')
        .withCategory(Authorization)
        .withNumeral(ClientlessShortMediaTokenParseError)
        .build();
    return authError;
};
/** @hidden */
export const createUserMetadataNetworkError = (networkError) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
            404: ClientlessUserMetadataNotFound,
            412: ClientlessUserMetadataExpiredToken,
        };
        numeral = codeToNumeralMap[status] || ClientlessUserMetadataServiceException;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Metadata)
        .withNumeral(numeral)
        .build();
    return authError;
};
/** @hidden */
export const createUserMetadataParseError = (parseError) => {
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(String(parseError) || 'User metadata response data JSON parse failure')
        .withCategory(Metadata)
        .withNumeral(ClientlessUserMetadataParseError)
        .build();
    return authError;
};
/** @hidden */
export const createLogoutNetworkError = (networkError) => {
    let message = String(networkError) || 'Unknown failure';
    let numeral = ClientlessUnknownError;
    if (HTTPError.is(networkError)) {
        const { response } = networkError;
        const { status } = response;
        const codeToNumeralMap = {
            400: ClientlessAPIBadRequest,
        };
        numeral = codeToNumeralMap[status] || ClientlessAPIUnexpectedResponseCode;
        message = `${status} ${describeNumeral(numeral)}`;
    }
    const authError = new ClientlessAuthErrorBuilder()
        .withMessage(message)
        .withCategory(Authentication)
        .withNumeral(numeral)
        .build();
    return authError;
};
