/** @hidden */
export var AuthEngineResponseType;
(function (AuthEngineResponseType) {
    AuthEngineResponseType["Did_Set_Requestor_Complete"] = "didSetRequestorComplete";
    AuthEngineResponseType["Did_Set_Authentication_Status"] = "didSetAuthenticationStatus";
    AuthEngineResponseType["Did_Receive_Selected_Provider"] = "didReceiveSelectedProvider";
    AuthEngineResponseType["Did_Set_Token"] = "didSetToken";
    AuthEngineResponseType["Did_Receive_Preauthorized_Resources"] = "didReceivePreauthorizedResources";
    AuthEngineResponseType["Did_Set_Metadata_Status"] = "didSetMetadataStatus";
})(AuthEngineResponseType || (AuthEngineResponseType = {}));
