export class Deferred {
    constructor() {
        this._promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
        this.resolve = this.resolve.bind(this);
        this.reject = this.reject.bind(this);
        this.then = this.then.bind(this);
        this.catch = this.catch.bind(this);
        this.finally = this.finally.bind(this);
    }
    get promise() {
        return this._promise;
    }
    resolve(value) {
        if (this._resolve)
            this._resolve(value);
        return this.promise;
    }
    reject(reason) {
        if (this._reject)
            this._reject(reason);
        return this.promise;
    }
    then(...args) {
        return Promise.prototype.then.apply(this.promise, args);
    }
    catch(...args) {
        return Promise.prototype.catch.apply(this.promise, args);
    }
    finally(onFinally) {
        if ('finally' in Promise.prototype && typeof Promise.prototype.finally === 'function') {
            return Promise.prototype.finally.apply(this.promise, onFinally);
        }
        if (typeof onFinally !== 'function') {
            return Promise.prototype.then.call(this.promise, (x) => Promise.resolve(onFinally).then(() => x), (e) => Promise.resolve(onFinally).then(() => {
                throw e;
            }));
        }
        return Promise.prototype.then.call(this.promise, (x) => Promise.resolve(onFinally()).then(() => x), (e) => Promise.resolve(onFinally()).then(() => {
            throw e;
        }));
    }
}
